let BASE_URL;
let API_PATH;
let USERNAME;
let APP_PASSWORD;
let GF_CONSUMER_KEY;
let GF_CONSUMER_SECRET;

let RECAPTCHA_SITE_KEY;
//let RECAPTCHA_SECRET_KEY;

let GTAG_ID;

BASE_URL = 'https://otagoinnovation.com/wordpress';
//BASE_URL = 'https://phpstack-108605-4569849.cloudwaysapps.com/wordpress';
//BASE_URL = 'https://otago-innovation.local';

API_PATH = '/wp-json/wp/v2';
USERNAME = 'effigyadmin'; // The username of your WordPress user
APP_PASSWORD = 'iYVT mGW7 bvwm gkUh HE4k Ohjq';
//APP_PASSWORD = 'kJgN 0iDT TGKK U14T p358 igNx';
//APP_PASSWORD = 'iEMQ zSTj WPS6 JLBO lI5G rpIh'; // Generated by WordPress

// Gravity Forms API Keys
GF_CONSUMER_KEY = 'ck_f5fdc35c9560d1d4a32662cfe29aa9e2d4fbea63';
GF_CONSUMER_SECRET = 'cs_b0c529a275b1b0e06a32c0c0cf7a443e431d635f';

// Google reCAPTCHA Site Key
RECAPTCHA_SITE_KEY = '6LdWSa4pAAAAANm6C_8OnxeS143pg3WBXWqoLhCH';
//RECAPTCHA_SECRET_KEY = '6LdWSa4pAAAAACbIdab-OyMdOc5chIJopSaGuM59';

GTAG_ID = 'G-FL3XFMHZZR';

export { BASE_URL, API_PATH, USERNAME, APP_PASSWORD, GF_CONSUMER_KEY, GF_CONSUMER_SECRET, RECAPTCHA_SITE_KEY, GTAG_ID };