import React, { useState, useEffect } from 'react';

import '../styles/child-theme.scss';
import {fetchImageURL} from "../api/media";
import ConvertToWebpUrl from "../hooks/useConvertToWebpUrl";

const TypingAnimation = ({ texts, subhead, image }) => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState('');
    const [showCursor, setShowCursor] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [loading, setLoading] = useState(true);

    const convertToWebpUrl = (url) => {
        return url.replace('/wp-content/uploads/', '/wp-content/webp-express/webp-images/uploads/') + '.webp';
    };

    useEffect(() => {
        if (image) {
            fetchImageURL(image).then(url => {
                const webpUrl = ConvertToWebpUrl(url);
                setImageUrl(url);
                setLoading(false); // Set loading to false once images are loaded
            });
        }
    }, [image]);

    useEffect(() => {
        if (fadeOut) {
            setTimeout(() => {
                setDisplayedText('');
                setFadeOut(false);
                setShowCursor(true);
                setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
            }, 1000); // Delay before switching text
            return;
        }

        const currentText = texts[currentTextIndex];

        if (displayedText.length < currentText.length) {
            setTimeout(() => {
                setDisplayedText(currentText.slice(0, displayedText.length + 1));
            }, 100);  // Adjust typing speed
        } else if (displayedText.length === currentText.length) {
            setTimeout(() => {
                setShowCursor(false);
                setFadeOut(true);
            }, 2000);  // 2 seconds pause
        }
    }, [displayedText, fadeOut, currentTextIndex, texts]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100" style={{ minHeight: '100px' }}>
                <div className="spinner-grow text-secondary fs-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <section className="home-page-banner bg-primary" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="container h-100">
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-12 text-center">
                        <div className={`typing-container fw-extrabold ${fadeOut ? 'fade-out' : ''}`}>
                            {displayedText}
                            {showCursor && <span className="cursor"> |</span>}
                        </div>
                        <h5 className="text-white fs-2 mt-3" dangerouslySetInnerHTML={{ __html: subhead }} />;
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TypingAnimation;