// Build component to get partner info from partner.json
// Display them in a row
// Path: src/components/PartnerComponent.js
import React, { useState, useEffect } from 'react';
import { fetchAllPartners } from "../api/partners";
import { fetchImageURL } from "../api/media";
import { Container, Row, Col } from 'reactstrap';

import '../styles/child-theme.scss';

function PartnerComponent() {

    const [partners, setPartners] = useState(null);
    const [error, setError] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const [loading, setLoading] = useState(true); // New state for tracking loading status

    useEffect(() => {
        fetchAllPartners()
            .then(data => {
                setPartners(data);
                return data; // Return data for chaining
            })
            .then(partnersData => {
                // Fetch images after partners are loaded
                const fetchImages = async () => {
                    const urls = await Promise.all(partnersData.map(async (partner) => {
                        return fetchImageURL(partner.logo);
                    }));
                    setImageUrls(urls);
                    setLoading(false); // Set loading to false once images are loaded
                };
                fetchImages();
            })
            .catch(err => {
                setError(err.message);
                setLoading(false); // Ensure loading is set to false in case of an error
            });
    }, []);

    if (loading) {
        // Render a loader or placeholder while the data is being fetched
        return (
            <div className="d-flex justify-content-center align-items-center vh-100" style={{ minHeight: '100px' }}>
                <div className="spinner-grow text-secondary fs-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );

    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Container className={"py-5"}>
            <Row>
                <Col className="text-center">
                    <h2 className="wp-block-heading text-primary">Our Partners</h2>
                </Col>
            </Row>
            <Row className={"row-layout-5"}>
                {partners && imageUrls && partners.map((partner, index) => {
                    return (
                        <Col key={index}>
                            <img className={"img-fluid"} src={imageUrls[index]} alt={partner.name} />
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );

}

export default PartnerComponent;
