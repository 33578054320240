import React, { useEffect, useState } from 'react';

// Create Button Component that accepts Label, Link and color as props

function ButtonComponent({label, link, color}) {
    // Set default color to primary
    color = color || 'primary';
    return (
        <a className={`btn btn-${color}`} href={link}>{label}</a>
    );
}

export default ButtonComponent;

