import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                const elementId = location.hash.substring(1);
                const element = document.getElementById(elementId);
                console.log(`Element ID: ${elementId}`, element);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 2000); // Adjust the delay as needed
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);


    return null; // This component does not render anything
}

export default ScrollToTop;