import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

// Import api functions
import { fetchAllSuccessStories, fetchSuccessStoryBySlug } from "../api/success-story";
import { fetchImageURL } from "../api/media";
import { fetchTeamMemberById } from "../api/team";

// Import FontAwesome and required icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

// Import SEO component
import SEOComponent from "./SEOComponent";

// Import hooks
import useDelayedVisibility from "../hooks/useDelayedVisibility";

function SuccessStoryDetailComponent( { slug }){

    //const { slug } = useParams();

    const [story, setStory] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [stories, setStories] = useState([]);
    const [teamMember, setTeamMember] = useState(null);
    const [teamMemberBG, setTeamMemberBG] = useState(null);

    //set loading and loaded const
    //const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    //set error const
    const [error, setError] = useState(null);

    // Set stories current index for nav
    const currentIndex = stories.findIndex(story => story.slug === slug);

    const TelephoneLink = ({ number }) => {
        const [showNumber, setShowNumber] = useState(false);

        const toggleNumberVisibility = () => {
            setShowNumber(!showNumber);
        };

        return (
            <div className="d-flex align-items-center social-icon-link">
                <a href={`tel:${showNumber ? number : ''}`} target="_parent">
        <span className="fa-stack fa-1x" onClick={toggleNumberVisibility}>
          <i className="fa-solid fa-circle fa-stack-2x"></i>
          <i className="fa-solid fa-phone fa-stack-1x fa-inverse"></i>
        </span>
                </a>
                {showNumber && (
                    <span className="fw-semibold text-primary ms-2">{number}</span>
                )}
            </div>
        );
    };

    function getRelativePath(absoluteUrl) {
        try {
            const url = new URL(absoluteUrl);
            let pathname = url.pathname;
            // Check if the pathname starts with '/wordpress' and remove it
            if (pathname.startsWith('/wordpress')) {
                pathname = pathname.substring('/wordpress'.length);
            }
            return pathname;
        } catch (error) {
            console.error('Invalid URL:', absoluteUrl);
            return ''; // Return a fallback path or handle as appropriate
        }
    }

    // fetch all stories for nav
    useEffect(() => {
        fetchAllSuccessStories()
            .then(data => {
                //console.log(data);
                setStories(data); // Ensure that you always set an array
            })
            .catch(err => setError(err.message));
    }, []);

    // fetch story by slug
    useEffect(() => {

        const abortController = new AbortController();
        const { signal } = abortController;

        setError(null);

        setLoaded(true);

        fetchSuccessStoryBySlug(slug, { signal })

            .then(storyDataArray => {

                // Ensure that storyDataArray is not empty and has a structure we expect
                if (storyDataArray.length > 0 && storyDataArray[0].acf ) {
                    const storyData = storyDataArray[0]; // Get the first (and presumably only) story
                    setStory(storyData);
                    //console.log(storyData);
                    // Since team_member_background is saved as an ID, fetch the image URL
                    if (storyData.acf.team_member_background) {
                        fetchImageURL(storyData.acf.team_member_background)
                            .then(backgroundImageUrl => {
                                setTeamMemberBG(backgroundImageUrl); // Save background image URL to state
                            })
                            .catch(error => console.error('Failed to fetch team member background image', error));
                    }
                    // Since team_member is an array, we grab the first ID from it
                    const teamMemberId = storyData.acf.team_member[0];
                    return fetchTeamMemberById(teamMemberId)
                    // Proceed to fetch the team member with this ID
                } else {
                    // Handle case where the array is empty or the structure is not as expected
                    setLoaded(true);
                    throw new Error('StoryData is not in the expected format or is empty.');
                }
            })
            .then(teamMemberDataArray => {
                // console.log(teamMemberDataArray);
                // Handle the team member data similarly, ensuring it's not an empty array
                const teamMemberData = teamMemberDataArray; // Get the first team member
                setTeamMember(teamMemberData);
                // Continue the chain if needed, for example fetching the team member's image
                if (teamMemberData.acf && teamMemberData.acf.team_member_image_alt) {
                    return fetchImageURL(teamMemberData.acf.team_member_image_alt);
                }
            })
            .then(imageUrl => {
                setImageUrl(imageUrl);
            })
            .catch(err => {
                // Check if the error is an abort error
                if (err.name === 'AbortError') {
                    // If so, it was an expected situation (like a component unmounting)
                    // You can simply return or do some other cleanup if necessary
                    console.log('Fetch aborted:', err);
                } else {
                    // It's another type of error which we didn't expect
                    // Handle the error as you already do
                    setError(err.message);
                }
            })
            .finally(() => {
                if (!signal.aborted) {
                    setLoaded(true);
                }
            });

        // Cleanup function to cancel the request if the component unmounts
        return () => abortController.abort();

    }, [slug]);


    if (error) {
        return <div className="container">Error: {error}</div>;
    }

    if (!story) {
        return null; // or return some kind of loader/spinner here
    }

    function Navigation({ story, stories, currentIndex, loaded, delay }) {

        const navVisible = useDelayedVisibility(loaded, delay);

        // Handle missing or empty stories
        if (!Array.isArray(stories) || stories.length === 0) {
            return null; // Or some placeholder component
        }

        return (
            <div className={`container-fluid bg-light mb-5 py-3`}>
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-md-auto text-center text-md-start">
                        <Link className="btn btn-link fw-bold text-decoration-none" to="/success-stories">
                            <FontAwesomeIcon icon="users" className="text-secondary me-2"/> Success Stories
                        </Link>
                    </div>
                    <div className="col text-center d-none d-lg-block">
                        <div
                            className="text-primary fw-bold"
                            dangerouslySetInnerHTML={{ __html: story.title && story.title.rendered }} />
                    </div>
                    <div className="col-12 col-md-auto text-end d-flex justify-content-between">
                        {currentIndex < stories.length - 1 ? (
                            <Link className="btn btn-link fw-bold text-decoration-none" to={`/success-story/${stories[currentIndex + 1].slug}/`}>
                                <FontAwesomeIcon icon="arrow-left" className="text-secondary me-2"/>Previous
                            </Link>
                        ) : (
                            <span className="btn btn-link fw-bold text-decoration-none disabled">
                            <FontAwesomeIcon icon="arrow-left" className="text-secondary me-2"/>Previous
                        </span>
                        )}
                        {currentIndex > 0 ? (
                            <Link className="btn btn-link fw-bold text-decoration-none" to={`/success-story/${stories[currentIndex - 1].slug}/`}>
                                Next<FontAwesomeIcon icon="arrow-right" className="text-secondary ms-2"/>
                            </Link>
                        ) : (
                            <span className="btn btn-link fw-bold text-decoration-none disabled">
                            Next<FontAwesomeIcon icon="arrow-right" className="text-secondary ms-2"/>
                        </span>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    function GetTeamMember({teamMember, teamMemberBG, imageUrl, storyTitle, loaded, delay}){

        const teamVisible = useDelayedVisibility(loaded, delay);

        if (!teamMember) return null; // Return null or a loader if teamMember data is not available

        // Extract the path from teamMember.link
        const teamMemberUrl = new URL(teamMember.link);
        //const path = teamMemberUrl.pathname;
        const path = getRelativePath(teamMemberUrl);

        //console.log();

        // Customize storyTitle if it equals 'Engage'
        const displayTitle = storyTitle === 'Engage' ? 'ENGAGE™' : storyTitle;

        return(
            <section
                className={`story-contact bg-light py-3`}
                style={{
                    backgroundImage: `url(${teamMemberBG})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                <div className={`container`}>
                    <div className="row py-5 align-items-center">
                        <div className="col-12 col-md-6">
                            <div className={"row"}>
                                <div className={"col"}>
                                    {/* Load the TeamMember image from teamMember.acf.team_member_image */}
                                        <img
                                            src={imageUrl}
                                            alt={teamMember.title.rendered}
                                            className="img-fluid mb-4"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className={"row flex-column"}>
                                <div className={"col text-center"}>
                                    <p className="lead mb-0 fw-semibold">
                                        Lead Commercialisation Manager for { displayTitle }
                                    </p>
                                    <h3 className="mb-0" dangerouslySetInnerHTML={{ __html: teamMember.title.rendered }} />
                                </div>
                                <div className={"col text-center"}>
                                    <Link to={path} className="btn btn-secondary btn-lg my-4">View Profile</Link>
                                </div>
                                <div className={"col text-center"}>
                                    {/* display social icons for team member */}
                                    <div className={"social-icons d-inline-flex flex-row align-items-center"}>
                                        {teamMember.acf.team_member_contacts.linkedin && (
                                            <a
                                                href={teamMember.acf && teamMember.acf.team_member_contacts.linkedin}
                                                target={"_blank"}
                                                className="social-icon-link me-2"
                                            >
                                                <span className="fa-stack fa-1x">
                                                    <i className="fa-solid fa-circle fa-stack-2x"></i>
                                                    <i className="fa-brands fa-linkedin-in fa-stack-1x fa-inverse"></i>
                                                </span>
                                            </a>
                                        )}
                                        {teamMember.acf.team_member_contacts.email_address && (
                                            <a
                                                href={`mailto:${teamMember.acf && teamMember.acf.team_member_contacts.email_address}`}
                                                target={"_parent"}
                                                className="social-icon-link me-2"
                                            >
                                                <span className="fa-stack fa-1x">
                                                    <i className="fa-solid fa-circle fa-stack-2x"></i>
                                                    <i className="fa-solid fa-envelope fa-stack-1x fa-inverse"></i>
                                                </span>
                                            </a>
                                        )}
                                        {teamMember.acf.team_member_contacts.telephone && (

                                            <TelephoneLink number={teamMember.acf.team_member_contacts.telephone} />

                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    function GetContent({story, loaded, delay }) {

        const contentVisible = useDelayedVisibility(loaded, delay);

        return(
            <div className="container">
                <div className={`col`} dangerouslySetInnerHTML={{ __html: story.content.rendered }} />
            </div>
        );
    }


    return (
        <div key={story?.id}>

            {/* Insert SEO meta tags */}
            <SEOComponent yoastHeadContent={story.yoast_head} />

            {/* Display loading spinner */}
            {!loaded && (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
                    <div className="spinner-grow text-secondary fs-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            {/* Content will be displayed when loading is complete */}
            {loaded && teamMember && imageUrl && (
                <>
                    {/* Get navigation section */}
                    <Navigation
                        story={story}
                        stories={stories}
                        currentIndex={currentIndex}
                        loaded={loaded}
                        delay={100}
                    />

                    {/* Get content section */}
                    <GetContent
                        story={story}
                        loaded={loaded}
                        delay={150}
                    />

                    {/* Render GetTeamMember */}
                    <GetTeamMember
                        teamMember={teamMember}
                        teamMemberBG={teamMemberBG}
                        imageUrl={imageUrl}
                        storyTitle={story.title.rendered}
                        loaded={loaded}
                        delay={200}
                    />
                </>
            )}

        </div>
    );


}

export default SuccessStoryDetailComponent;



