import { BASE_URL, API_PATH, USERNAME, APP_PASSWORD } from '../config';

const authHeader = 'Basic ' + btoa(USERNAME + ':' + APP_PASSWORD);

// Add function to fetch Success Story by slug
export const fetchSuccessStoryBySlug = (slug, options = {}) => {
    return fetch(`${BASE_URL}${API_PATH}/success-story?slug=${slug}`, {
        headers: {
            'Authorization': authHeader
        },
        signal: options.signal
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Success Story response was not ok');
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            throw error; // Re-throwing the error to be caught by the caller
        });
}

// Add function to fetch all Success Stories
export const fetchAllSuccessStories = () => {
    return fetch(`${BASE_URL}${API_PATH}/success-story`, {
        headers: {
            'Authorization': authHeader
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        });
}