import { BASE_URL, API_PATH, USERNAME, APP_PASSWORD } from '../config';

const authHeader = 'Basic ' + btoa(USERNAME + ':' + APP_PASSWORD);

export const fetchPosts = (page = 1) => {
    return fetch(`${BASE_URL}${API_PATH}/posts?_embed&page=${page}&per_page=9`, {
        headers: {
            'Authorization': authHeader
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const totalPages = response.headers.get('X-WP-TotalPages');
            return response.json().then(data => ({
                posts: data,
                totalPages: parseInt(totalPages, 10)
            }));
        });
};


export const fetchPostById = (postId) => {
    return fetch(`${BASE_URL}${API_PATH}/posts/${postId}`, {
        headers: {
            'Authorization': authHeader
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log(response);
            return response.json();
        });
};

export const fetchPostBySlug = (slug) => {
    return fetch(`${BASE_URL}${API_PATH}/posts?slug=${slug}`, {
        headers: {
            'Authorization': authHeader
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Get Post by Slug response was not ok');
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            throw error; // Re-throwing the error to be caught by the caller
        });
}