import React from 'react';

// Components
import PageContentComponent from "../components/PageContentComponent";

function GenericPage({ pageId, previewMode = false }) { // Add previewMode prop with default value
    return (
        <PageContentComponent pageId={pageId} previewMode={previewMode} />
    );
}

export default GenericPage;