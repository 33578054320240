import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GTAG_ID } from '../config';

const useGA4Tracker = () => {
    const location = useLocation();

    useEffect(() => {
        const sendPageView = (path) => {

            //console.log(`Sending page view for ${path} to GA4 ID: ${GTAG_ID}`);

            // Ensure gtag is defined and send a page_view event to GA4
            if (window.gtag) {
                window.gtag('config', GTAG_ID, {
                    page_path: path,
                });
            }
        };

        // Call sendPageView with the current path
        sendPageView(location.pathname + location.search);
    }, [location]);
};

export default useGA4Tracker;