import React, { useEffect, useState } from "react";
import { fetchPageBySlug, fetchPastWinners } from "../api/pages";
import SEOComponent from "./SEOComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function PastWinnerComponent({ slug }) {
    const [page, setPage] = useState(null);
    const [pastWinners, setPastWinners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const currentIndex = pastWinners.findIndex(pastWinner => pastWinner.slug === slug);

    // fetch all past winners
    useEffect(() => {
        fetchPastWinners()
            .then(pages => {
                setPastWinners(pages);
            })
            .catch(error => {
                setError(error);
            });
    }, []);

    useEffect(() => {
        if (slug) {
            fetchPageBySlug(slug)
                .then(pageData => {
                    if (pageData.length > 0) {
                        setPage(pageData[0]); // Get the first page with the matching slug
                        setLoading(false);
                    } else {
                        throw new Error('Page not found');
                    }
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, [slug]);

    function Navigation({ thisWinner, pastWinners, currentIndex, delay }) {

        //const navVisible = useDelayedVisibility(loaded, delay);

        // Handle missing or empty stories
        if (!Array.isArray(pastWinners) || pastWinners.length === 0) {
            return null; // Or some placeholder component
        }

        return (
            <div className={`container-fluid bg-light mb-5 py-3`}>
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-md-auto text-center text-md-start">
                        <Link className="btn btn-link fw-bold text-decoration-none" to="/funding/proof-of-concept-competition/#past-winners">
                            <FontAwesomeIcon icon="arrow-left" className="text-secondary me-2"/>Past Winners
                        </Link>
                    </div>
                    <div className="col text-center d-none d-lg-block">
                        <div
                            className="text-primary fw-bold"
                            dangerouslySetInnerHTML={{ __html: thisWinner.title && thisWinner.title.rendered }} />
                    </div>
                    <div className="col-12 col-md-auto text-end d-flex justify-content-between">
                        {currentIndex < pastWinners.length - 1 ? (
                            <Link className="btn btn-link fw-bold text-decoration-none" to={`/funding/past-winners/${pastWinners[currentIndex + 1].slug}/`}>
                                <FontAwesomeIcon icon="arrow-left" className="text-secondary me-2"/>Previous
                            </Link>
                        ) : (
                            <span className="btn btn-link fw-bold text-decoration-none disabled">
                            <FontAwesomeIcon icon="arrow-left" className="text-secondary me-2"/>Previous
                        </span>
                        )}
                        {currentIndex > 0 ? (
                            <Link className="btn btn-link fw-bold text-decoration-none" to={`/funding/past-winners/${pastWinners[currentIndex - 1].slug}/`}>
                                Next<FontAwesomeIcon icon="arrow-right" className="text-secondary ms-2"/>
                            </Link>
                        ) : (
                            <span className="btn btn-link fw-bold text-decoration-none disabled">
                            Next<FontAwesomeIcon icon="arrow-right" className="text-secondary ms-2"/>
                        </span>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        page ? (
            <div key={page.id}>

                {/* Insert SEO meta tags */}
                <SEOComponent yoastHeadContent={page.yoast_head} />

                {/* Get navigation section */}
                <Navigation
                    thisWinner={page}
                    pastWinners={pastWinners}
                    currentIndex={currentIndex}
                    delay={100}
                />

                {/* Insert Page Content */}
                <section className="container-fluid px-0">
                    <div className="col">
                        <div dangerouslySetInnerHTML={{ __html: page.content && page.content.rendered }} />
                    </div>
                </section>

            </div>
        ) : (
            <div className="d-flex justify-content-center align-items-center vh-100" style={{ minHeight: '100px' }}>
                <div className="spinner-grow text-secondary fs-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )

    )
}

export default PastWinnerComponent;
