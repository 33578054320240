import React from 'react';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';

function parseHTMLToJSX(htmlString) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const nodes = Array.from(doc.head.childNodes);

        return nodes.map((node, index) => {
                if (node.nodeType === 3) { // Text node, can be ignored (probably whitespace)
                        return null;
                }
                return <React.Fragment key={index} dangerouslySetInnerHTML={{ __html: node.outerHTML }} />;
        });
}

function SEOComponent({ yoastHeadContent }) {
        const elements = parse(yoastHeadContent);
        return (
                <Helmet>
                        {elements}
                </Helmet>
        );
}

export default SEOComponent;