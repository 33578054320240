// ConditionalPartnerComponent.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import PartnerComponent from "../components/PartnerComponent";

const ConditionalPartnerComponent = () => {
    const location = useLocation();
    const shouldShowPartnerComponent = !(
        location.pathname === '/' ||
        location.pathname === '/blog/' ||
        location.pathname === '/about/meet-the-team/' ||
        /^\/team/.test(location.pathname) ||
        location.pathname === '/funding/proof-of-concept-competition/' ||
        /\/success-stories\/?$/.test(location.pathname) ||
        /^\/success-story/.test(location.pathname) ||
        location.pathname === '/terms-conditions/' ||
        location.pathname === '/privacy-policy/' ||
        /\/register-your-interest\/?$/.test(location.pathname) ||
        /\/thank-you\/?$/.test(location.pathname)
    );
    return shouldShowPartnerComponent ? <PartnerComponent /> : null;
};

export default ConditionalPartnerComponent;
