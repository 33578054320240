// create page to display team member details

// Path: src/pages/TeamMemberDetailPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import TeamMemberDetailComponent from "../components/TeamMemberDetailComponent";

function TeamMemberDetail() {

    const { memberName } = useParams();

    // Fetch the team member details using memberName or display however you want
    return (
        <div className="App">
            <TeamMemberDetailComponent memberName={memberName} />
        </div>
    );

}

export default TeamMemberDetail;
