import { BASE_URL } from '../config';
export function adjustLinks(htmlString) {

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const anchors = doc.querySelectorAll('a');

    anchors.forEach(anchor => {
        let href = anchor.getAttribute('href');
        href = href.replace(`${BASE_URL}`, '');
        href = href.replace('/wordpress', '');
        anchor.setAttribute('href', href);
    });

    return doc.body.innerHTML;  // Return the adjusted HTML string
}

