import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchAllSuccessStories } from "../api/success-story";
import { fetchImageURL } from "../api/media";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';

// Components
import '../styles/child-theme.scss';

function getRelativePath(absoluteUrl) {
    try {
        const url = new URL(absoluteUrl);
        let pathname = url.pathname;
        // Check if the pathname starts with '/wordpress' and remove it
        if (pathname.startsWith('/wordpress')) {
            pathname = pathname.substring('/wordpress'.length);
        }
        return pathname;
    } catch (error) {
        console.error('Invalid URL:', absoluteUrl);
        return ''; // Return a fallback path or handle as appropriate
    }
}

function SuccessStorySliderComponent() {

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [stories, setStories] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // New state for tracking loading status

    const fetchStoriesAndImages = async () => {
        try {
            const fetchedStories = await fetchAllSuccessStories();

            const imageFetchPromises = fetchedStories.map(story => {
                const imgPromise = fetchImageURL(story.acf.slider.image);
                const bgImgPromise = fetchImageURL(story.acf.slider.bg_image);
                return Promise.all([imgPromise, bgImgPromise]);
            });

            const imageUrls = await Promise.all(imageFetchPromises);
            const storiesWithImages = fetchedStories.map((story, index) => ({
                ...story,
                src: imageUrls[index][0], // Main image URL
                bgSrc: imageUrls[index][1] // Background image URL
            }));

            const reversedStories = storiesWithImages.reverse();

            //console.log(reversedStories);
            setStories(reversedStories);

            setLoading(false); // Set loading to false once images are loaded
        } catch (err) {
            setError(err.message);
            setLoading(false); // Set loading to false once images are loaded
        }
    };

    useEffect(() => {
        fetchStoriesAndImages();
    }, []);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === stories.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? stories.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = stories.map((story, index) => {

        const bgStyle = {
            backgroundImage: `url(${story.bgSrc})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        };

        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={index}
            >
                <div className="row g-0 h-75">
                    <div className="col-lg-6">
                        <figure className={"fit-container-cover"}>
                            <img src={story.src} alt={story.altText} className="" />
                        </figure>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start p-5" style={bgStyle}>
                        <div className="w-75 text-center text-lg-start">
                            <h3 className={"text-white"}>{story.acf.slider.heading}</h3>
                            <p className={"text-white"}>{story.acf.slider.text}</p>
                            <Link to={getRelativePath(story.link)} className="btn btn-secondary btn-5">Learn More</Link>
                        </div>
                    </div>
                </div>
            </CarouselItem>
        );
    });

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!stories.length) {
        // Render a loader or placeholder while the data is being fetched
        return (
            <div className="d-flex justify-content-center align-items-center vh-100" style={{ minHeight: '100px' }}>
                <div className="spinner-grow text-secondary fs-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            <CarouselIndicators items={stories} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
    );
}

export default SuccessStorySliderComponent;
