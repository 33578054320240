import React from 'react';

// Assets
import '../styles/child-theme.scss';

function Error404Page() {
    return (
        <div className="App">
            <div className={"container"}>
                <section className='my-4'>
                    <h1>404 - Page Not Found</h1>
                    <p>The page you are looking for does not exist.</p>
                </section>
            </div>
        </div>
    );
}

export default Error404Page;