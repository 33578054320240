import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Import required api functions
import { fetchAllTeamMembers, fetchTeamMemberBySlug } from "../api/team";
import { fetchImageURL } from "../api/media";

// Import FontAwesome and required icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

// Import hooks
import useDelayedVisibility from "../hooks/useDelayedVisibility";

// Import SEO component
import SEOComponent from "./SEOComponent";

function TeamMemberDetail() {
    const { memberName } = useParams();
    const [teamMember, setTeamMember] = useState(null);
    const [allTeamMembers, setAllTeamMembers] = useState([]);
    const currentIndex = allTeamMembers.findIndex(member => member.slug === memberName);
    const [loading, setLoading] = useState(false);  // New state variable for loading
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);

    const TelephoneLink = ({ number }) => {
        const [showNumber, setShowNumber] = useState(false);

        const toggleNumberVisibility = () => {
            setShowNumber(!showNumber);
        };

        return (
            <div className="d-flex align-items-center social-icon-link">
                <a href={`tel:${showNumber ? number : ''}`} target="_parent">
        <span className="fa-stack fa-1x" onClick={toggleNumberVisibility}>
          <i className="fa-solid fa-circle fa-stack-2x"></i>
          <i className="fa-solid fa-phone fa-stack-1x fa-inverse"></i>
        </span>
                </a>
                {showNumber && (
                    <span className="fw-semibold text-primary ms-2">{number}</span>
                )}
            </div>
        );
    };


    // Fetch all team members for nav
    useEffect(() => {
        fetchAllTeamMembers()
            .then(data => {
                setAllTeamMembers(data);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    // use team.js to fetch the team member details using memberName
    useEffect(() => {

        setLoading(true);

        fetchTeamMemberBySlug(memberName)
            .then(data => {
                const [firstItem] = data;
                // Get Featured Image if it exists
                let featuredMediaPromise = Promise.resolve(firstItem);
                if (!firstItem._embedded || !firstItem._embedded['wp:featuredmedia'] || !firstItem._embedded['wp:featuredmedia'][0].source_url) {
                    if (firstItem.featured_media) {
                        featuredMediaPromise = fetchImageURL(firstItem.featured_media)
                            .then(url => {
                                firstItem.featuredMediaURL = url;
                                return firstItem;
                            })
                            .catch(err => {
                                console.error("Error fetching featured media:", err);
                                return firstItem;
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    }
                }
                return featuredMediaPromise;
            })
            .then(firstItem => {
                let acfMediaPromise = Promise.resolve(firstItem);
                if (!firstItem.acf || !firstItem.acf.image || !firstItem.acf.image.url) {
                    if (firstItem.acf && firstItem.acf.team_member_image) {
                        acfMediaPromise = fetchImageURL(firstItem.acf.team_member_image)
                            .then(url => {
                                firstItem.acfImageURL = url;
                                return firstItem;
                            })
                            .catch(err => {
                                console.error("Error fetching ACF media:", err);
                                return firstItem;
                            });
                    }
                }
                return acfMediaPromise;
            })
            .then(firstItem => {
                if (firstItem.acf && firstItem.acf.team_member_quotes && firstItem.acf.team_member_quotes[0].quote_background) {
                    return fetchImageURL(firstItem.acf.team_member_quotes[0].quote_background)
                        .then(url => {
                            firstItem.quoteBackgroundURL = url;
                            return firstItem;
                        })
                        .catch(err => {
                            console.error("Error fetching quote background media:", err);
                            return firstItem;
                        });
                } else {
                    return firstItem;
                }
            })
            .then(firstItem => {
                setTeamMember(firstItem);
                setLoaded(true);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setError(err.message);
                setLoading(false);
            });
    }, [memberName]);

    function Navigation({teamMember, allTeamMembers, currentIndex, loaded, delay }) {

        const navVisible = useDelayedVisibility(loaded, delay);

        return (
            <div className={`container-fluid bg-light mb-5 py-3 fade-in ${navVisible ? 'show' : ''}`}>
                <div className={"row align-items-center justify-content-around"}>
                    <div className={"col text-start"}>
                        <a className={"btn btn-link fw-bold text-decoration-none"} href={"/about/meet-the-team/"}>
                            <FontAwesomeIcon icon="users" className={"text-secondary me-2"}/>Our Team
                        </a>
                    </div>
                    <div className={"col text-center d-none d-lg-block"}>
                        <div
                            className={"text-primary fw-bold"}
                            dangerouslySetInnerHTML={{ __html: teamMember.title && teamMember.title.rendered }} />
                    </div>
                    <div className={"col text-end"}>
                        {/* Previous link */}
                        {currentIndex < allTeamMembers.length - 1 ? (
                            <a className="btn btn-link fw-bold text-decoration-none" href={`/team/${allTeamMembers[currentIndex + 1].slug}`}>
                                <FontAwesomeIcon icon="arrow-left" className={"text-secondary me-2"}/>Previous
                            </a>
                        ) : (
                            <a className="btn btn-link fw-bold text-decoration-none disabled">
                                <FontAwesomeIcon icon="arrow-left" className={"text-secondary me-2"}/>Previous
                            </a>
                        )}
                        {/* Next link */}
                        {currentIndex > 0 ? (
                            <a className="btn btn-link fw-bold text-decoration-none" href={`/team/${allTeamMembers[currentIndex - 1].slug}`}>
                                Next<FontAwesomeIcon icon="arrow-right" className={"text-secondary ms-2"}/>
                            </a>
                        ) : (
                            <a className="btn btn-link fw-bold text-decoration-none disabled">
                                Next<FontAwesomeIcon icon="arrow-right" className={"text-secondary me-2"}/>
                            </a>
                        )}
                    </div>
                </div>

            </div>
        );
    }

    function Profile({teamMember, loaded, delay}) {

        const profileVisible = useDelayedVisibility(loaded, delay);

        return (
            <div className={`pe-0 pe-lg-5 fade-in ${profileVisible ? 'show' : ''}`}>
                <h1
                    className={"text-primary"}
                    dangerouslySetInnerHTML={{ __html: teamMember.title && teamMember.title.rendered }}
                />
                <h3
                    className="text-tertiary fw-bold fs-5"
                    dangerouslySetInnerHTML={{ __html: teamMember.acf && teamMember.acf.team_member_position }}
                />
                <p
                    className={"text-muted fs-5 text-italic"}
                    dangerouslySetInnerHTML={{ __html: teamMember.acf && teamMember.acf.team_member_credentials }}
                />
                <div dangerouslySetInnerHTML={{ __html: teamMember.content && teamMember.content.rendered }} />

                <div className={"social-icons d-inline-flex flex-row align-items-center"}>
                    {teamMember.acf.team_member_contacts.linkedin && (
                        <a
                            href={teamMember.acf && teamMember.acf.team_member_contacts.linkedin}
                            target={"_blank"}
                            className="social-icon-link"
                        >
                            <span className="fa-stack fa-1x">
                                <i className="fa-solid fa-circle fa-stack-2x"></i>
                                <i className="fa-brands fa-linkedin-in fa-stack-1x fa-inverse"></i>
                            </span>
                        </a>
                    )}
                    {teamMember.acf.team_member_contacts.email_address && (
                        <a
                            href={`mailto:${teamMember.acf && teamMember.acf.team_member_contacts.email_address}`}
                            target={"_parent"}
                            className="social-icon-link"
                        >
                            <span className="fa-stack fa-1x">
                                <i className="fa-solid fa-circle fa-stack-2x"></i>
                                <i className="fa-solid fa-envelope fa-stack-1x fa-inverse"></i>
                            </span>
                        </a>
                    )}
                    {teamMember.acf.team_member_contacts.telephone && (

                        <TelephoneLink number={teamMember.acf.team_member_contacts.telephone} />

                    )}
                </div>
            </div>
        );
    }

    function MemberImage({ imageURL, altText }) {
        const imageVisible = useDelayedVisibility(loaded, 650);
        return (
            <img
                className={`img-fluid fade-in ${imageVisible ? 'show' : ''} `}
                src={imageURL}
                alt={altText}
            />
        );
    }

    function Quotes({quotes, teamMember, loaded, delay}) {

        const quotesVisible = useDelayedVisibility(loaded, delay);

        return (
            <section
                className={`py-5 fade-in ${quotesVisible ? 'show' : ''}`}
                style={{
                    backgroundImage: `url(${teamMember.quoteBackgroundURL})`,
                    backgroundSize: 'cover'
                }}
            >
                <div className={"container py-3"}>
                    <div className={"row"}>
                        <div className={"col text-center"}>
                            {/* Iterate over the team_member_quotes array and render each quote */}
                            {teamMember.acf && teamMember.acf.team_member_quotes && teamMember.acf.team_member_quotes.map((quote, index) => (
                                <div key={index} className="quote">
                                    <p className={"text-primary fs-4 fw-light mb-2"}>"<i>{quote.quote}"</i></p>
                                    <p className={"text-primary fs-4 fw-bold mb-0"}>- {quote.quote_source}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }


    return (
        <div key={teamMember?.id}>

            {/* Insert SEO meta tags */}
            {teamMember && <SEOComponent yoastHeadContent={teamMember.yoast_head} />}

            {/* Display loading spinner */}
            {loading && (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
                    <div className="spinner-grow text-secondary fs-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            {/* Content will be displayed when loading is complete */}
            {!loading && teamMember && (
                <>
                    {/* Get navigation section */}
                    <Navigation teamMember={teamMember} allTeamMembers={allTeamMembers} currentIndex={currentIndex} loaded={loaded} delay={200}  />

                    <div className={"container my-5"}>
                        <div className={"row row-cols-1 row-cols-lg-2 g-3"}>
                            <div className={"col-12 col-lg-6 order-1 order-lg-0 pe-lg-0"}>
                                {/* Get team profile content */}
                                <Profile teamMember={teamMember} loaded={loaded} delay={450}/>
                            </div>
                            <div className={"col-12 col-lg-6 order-0 order-lg-1"}>
                                {/* Get member image */}
                                <MemberImage
                                    imageURL={teamMember.acfImageURL || (teamMember.acf && teamMember.acf.image && teamMember.acf.image.url)}
                                    altText={teamMember.title && teamMember.title.rendered}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Get quote section */}
                    <Quotes quotes={teamMember.acf && teamMember.acf.team_member_quotes} teamMember={teamMember} loaded={loaded} delay={850} />
                </>
            )}
        </div>
    );

}

export default TeamMemberDetail;