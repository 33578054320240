import { useState, useEffect } from 'react';

function useDelayedVisibility(propLoaded, delay = 100) {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (propLoaded) {
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, delay);
            return () => clearTimeout(timer);
        }
    }, [propLoaded, delay]);

    return isVisible;
}

export default useDelayedVisibility;