import React, { useState } from 'react';
import AppRouter from "./AppRouter";

import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Assets
import './styles/child-theme.scss';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, fab, far);

function App() {

  return (
        <div className="App">
            <AppRouter/>
        </div>
  );

}

export default App;
