import React, {useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchFormById, submitFormById } from "../api/forms";

// Recaptcha
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from "../config";

// Assets
import Lottie from "lottie-react";
import checkmarkAnimation from '../assets/animated-tick.json';

function GravityFormsComponent({ formId }) {

    const [formFields, setFormFields] = useState([]);
    const [formData, setFormData] = useState({});
    const [buttonText, setButtonText] = useState('Submit'); // Default button text
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const navigate = useNavigate();

    const transformFormData = (fields) => {
        // Transform the fields data to match the structure that your React component expects
        // Implement the specific transformations that you need based on your form's structure

        return fields.map(field => {

            //console.log(fields);

            if (field.type === 'name' && Array.isArray(field.inputs)) {
                return {
                    id: field.id,
                    label: field.label,
                    type: field.type,
                    isRequired: field.isRequired,
                    errorMessage: field.errorMessage,
                    inputs: field.inputs.map(input => ({ // Map through inputs to create a structure for subfields
                        id: input.id,
                        label: input.label,
                        name: input.name,
                        placeholder: input.placeholder,
                        type: input.inputType || 'text', // Default to text if not specified
                        isRequired: field.isRequired, // Assuming subfields are required if the main field is required
                        isHidden: input.isHidden,
                        // Include any other transformations needed for subfields
                    })),
                };
            }

            return {
                id: field.id,
                label: field.label,
                type: field.type,
                description: field.description,
                placeholder: field.placeholder || field.label,
                isRequired: field.isRequired,
                errorMessage: field.errorMessage,
                autocompleteAttribute: field.autocompleteAttribute,
                content: field.content,
                conditionalLogic: field.conditionalLogic,
                choices: field.choices, // If it's a dropdown, you might have choices here
                // Include any other transformations needed for your form fields
            };
        });
    };

    useEffect(() => {
        fetchFormById(formId)
            .then(data => {
                if (data && data.fields) {

                    const transformedData = transformFormData(data.fields);
                    setFormFields(transformedData);

                    //console.log(data);

                    // Set the button text from the fetched data
                    if (data.button && data.button.text) {
                        setButtonText(data.button.text);
                    }

                    // Initialize formData with fields
                    const initialFormData = data.fields.reduce((formData, field) => {
                        if (field.type === 'name') {
                            // Include each part of the name field
                            field.inputs.forEach(input => {
                                formData[`input_${input.id}`] = '';
                            });
                        } else {
                            // Initialize other field types
                            formData[`input_${field.id}`] = '';
                        }
                        return formData;
                    }, {});

                    setFormData(initialFormData);
                }
            })
            .catch(error => {
                console.error('Error fetching form:', error);
                // Additional error handling
            });
    }, [formId]);


    const onReCAPTCHAChange = (value) => {
        // Here you need to handle the change and store the recaptcha value.
        // You will send this to your backend to verify it.
        setRecaptchaValue(value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const extractConfirmationMessage = (htmlString) => {
        // Creating a new DOM parser
        const parser = new DOMParser();
        // Parsing the string to a new document
        const doc = parser.parseFromString(htmlString, 'text/html');
        // Querying the document for the confirmation message
        const messageElement = doc.querySelector('.gform_confirmation_message');
        // Returning the text content of the message element, or a default message if not found
        return messageElement ? messageElement.textContent : "Thank you!";
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        // Perform validation
        let errors = {};

        // Check if the form contains a ReCAPTCHA field
        const formContainsReCAPTCHA = formFields.some(field => field.type === 'captcha');

        // Validate each field as required
        formFields.forEach((field) => {
            if (field.type === 'name' && field.isRequired) {
                let nameFieldErrors = [];
                // Check each subfield for input if the main field is required
                field.inputs.forEach(subField => {
                    const subValue = formData[`input_${subField.id}`] || '';
                    // Define which subfields are inherently required
                    if ((subField.label === 'First' || subField.label === 'Last') && !subValue.trim()) {
                        nameFieldErrors.push(`${subField.label} is required.`);
                    }
                });
                // If there are any errors for the name subfields, concatenate them and assign to the main field's error message
                if (nameFieldErrors.length > 0) {
                    errors[`input_${field.id}`] = field.errorMessage || nameFieldErrors.join(' ');
                }
            } else if (field.type === 'phone') {
                const phoneValue = formData[`input_${field.id}`] || '';
                if (field.isRequired && !phoneValue.trim()) {
                    // If the phone field is required and the value is empty
                    errors[`input_${field.id}`] = field.errorMessage || "Phone is required.";
                }
            } else {
                // Handle other field types like 'email', 'text', etc.
                const value = formData[`input_${field.id}`] || '';
                if (field.isRequired && !value.trim()) {
                    errors[`input_${field.id}`] = field.errorMessage || `${field.label} is required.`;
                } else if (field.type === 'email' && !/\S+@\S+\.\S+/.test(value)) {
                    errors[`input_${field.id}`] = field.errorMessage || "Email is invalid.";
                } else {
                    // Field is not required and is empty, ensure no error is set for this field
                    if (errors[`input_${field.id}`]) {
                        delete errors[`input_${field.id}`];
                    }
                }
            }
        });

        const getPathFromUrl = (url) => {
            const path = new URL(url).pathname;
            return path;
        };

        // Check if reCAPTCHA is filled out
        if (formContainsReCAPTCHA && !recaptchaValue) {
            // Handle the case where reCAPTCHA is not filled out
            errors.recaptcha = "Please verify that you are not a robot.";
        }

        // Update validation errors
        setValidationErrors(errors);

        //console.log(errors);

        // If there are any errors, do not submit
        if (Object.keys(errors).length > 0) return;

        const payload = { ...formData, 'g-recaptcha-response': recaptchaValue };

        submitFormById(formId, payload)
            .then(data => {

                //console.log(data);

                if (data && data.is_valid) {
                    if (data.confirmation_type === 'redirect' && data.confirmation_redirect) {
                        // Redirect to the specified URL
                        const path = getPathFromUrl(data.confirmation_redirect);
                        navigate(path); // Using React Router's navigate for SPA-like behavior
                        // window.location.href = data.confirmation_redirect; // Uncomment if you want a full page redirect
                    } else {
                        // No redirect specified, handle as a successful submission
                        // Optionally set a default or response-based confirmation message
                        const message = extractConfirmationMessage(data.confirmation_message || '');
                        setConfirmationMessage(message);
                        setIsSubmitted(true);

                        // Reset form data for next submission if needed
                        const resetFormData = formFields.reduce((acc, field) => {
                            acc[`input_${field.id}`] = '';
                            return acc;
                        }, {});
                        setFormData(resetFormData);
                        setValidationErrors({});
                    }
                } else {
                    // Handle validation errors if form is not valid
                    setValidationErrors(data.validation_messages);
                }
            })
            .catch(error => {
                console.error('Error submitting form:', error);
                // Here you might want to set an error state to inform the user of the failed submission
            });
    };

    const evaluateConditionalLogic = (field, formData) => {

        //console.log(field);
        //console.log(formData);

        if (!field.conditionalLogic || !field.conditionalLogic.enabled) {
            return true; // Display the field if conditional logic is not enabled
        }

        const { actionType, logicType, rules } = field.conditionalLogic;

        let conditionMet;
        if (logicType === 'any') {
            // Check if any of the rules are true
            conditionMet = rules.some(rule => {
                const targetFieldId = `input_${rule.fieldId}`;
                const targetValue = formData[targetFieldId];
                return rule.operator === 'is' && targetValue === rule.value;
            });
        } else {
            // Check if all of the rules are true
            conditionMet = rules.every(rule => {
                const targetFieldId = `input_${rule.fieldId}`;
                const targetValue = formData[targetFieldId];
                return rule.operator === 'is' && targetValue === rule.value;
            });
        }

        return actionType === 'show' ? conditionMet : !conditionMet;
    };


    return (
        <div className="container">
            {!isSubmitted ? (
                <form noValidate onSubmit={handleSubmit}>

                    {formFields.map(field => {

                        // Don't render the field if conditional logic fails
                        if (!evaluateConditionalLogic(field, formData)) {
                            return null;
                        }

                        switch (field.type) {
                            case 'text':
                                return (
                                    <div className="mb-3">
                                        {field.description && <p className="small mb-1 text-muted">{field.description}</p>}
                                        <div className="form-floating">
                                            <input
                                                key={field.id}
                                                type="text"
                                                className={`form-control ${validationErrors[`input_${field.id}`] ? 'is-invalid' : ''}`}
                                                id={`input_${field.id}`}
                                                name={`input_${field.id}`}
                                                placeholder={field.placeholder}
                                                value={formData[`input_${field.id}`] || ''}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor={`input_${field.id}`}>{field.placeholder || field.label}</label>
                                            <div id={`validationFeedback_${field.id}`} className="invalid-feedback">
                                                {validationErrors[`input_${field.id}`]}
                                            </div>
                                        </div>
                                    </div>
                                );

                            case 'textarea':
                                return (
                                    <div className="mb-3">
                                        {field.description && (<p className="small mb-1 text-muted">{field.description}</p>)}
                                        <div className="form-floating">
                                            <textarea
                                                key={field.id}
                                                className={`form-control ${validationErrors[`input_${field.id}`] ? 'is-invalid' : ''}`}
                                                id={`input_${field.id}`}
                                                name={`input_${field.id}`}
                                                placeholder={field.placeholder}
                                                value={formData[`input_${field.id}`] || ''}
                                                onChange={handleInputChange}
                                                style={{height: '100px'}} // Adjust the height as needed
                                            />
                                            <label htmlFor={`input_${field.id}`}>{field.placeholder || field.label}</label>
                                            <div id={`validationFeedback_${field.id}`} className="invalid-feedback">
                                                {validationErrors[`input_${field.id}`]}
                                            </div>
                                        </div>
                                    </div>
                                );
                            case 'name':
                                return (
                                    <div key={field.id} className="mb-3">
                                        <div className="row g-2">
                                            {field.inputs.filter(subField => !subField.isHidden).map(subField => {
                                                const subFieldKey = `input_${subField.id}`;
                                                const subFieldError = validationErrors[subFieldKey];
                                                return (
                                                    <div className="col-md">
                                                        <div key={subField.id} className="form-floating">
                                                            <input
                                                                type="text"
                                                                className={`form-control ${subFieldError ? 'is-invalid' : ''}`}
                                                                id={subFieldKey}
                                                                name={subFieldKey}
                                                                placeholder={subField.placeholder || subField.label}
                                                                value={formData[subFieldKey] || ''}
                                                                onChange={handleInputChange}
                                                            />
                                                            <label
                                                                htmlFor={subFieldKey}>{subField.placeholder || subField.label}</label>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div id={`validationFeedback_${field.id}`} className="invalid-feedback d-block">
                                            {validationErrors[`input_${field.id}`]}
                                        </div>
                                    </div>
                                );
                            case 'phone':
                                return (
                                    <div className="mb-3">
                                        {field.description && <p className="small mb-1 text-muted">{field.description}</p>}
                                        <div className="form-floating">
                                            <input
                                                key={field.id}
                                                type="tel"
                                                className={`form-control ${validationErrors[`input_${field.id}`] ? 'is-invalid' : ''}`}
                                                id={`input_${field.id}`}
                                                name={`input_${field.id}`}
                                                placeholder={field.placeholder || 'Phone'}
                                                value={formData[`input_${field.id}`]}
                                                onChange={handleInputChange}
                                                autoComplete={field.autocompleteAttribute}
                                            />
                                            <label htmlFor={`input_${field.id}`}>{field.placeholder || field.label}</label>
                                            <div id={`validationFeedback_${field.id}`} className="invalid-feedback">
                                                {validationErrors[`input_${field.id}`]}
                                            </div>
                                        </div>
                                    </div>
                                );
                            case 'email':
                                return (
                                    <div className="mb-3">
                                        {field.description && <p className="small mb-1 text-muted">{field.description}</p>}
                                        <div className="form-floating">
                                            <input
                                                key={field.id}
                                                type="email"
                                                className={`form-control ${validationErrors[`input_${field.id}`] ? 'is-invalid' : ''}`}
                                                id={`input_${field.id}`}
                                                name={`input_${field.id}`}
                                                placeholder={field.placeholder || 'Email'}
                                                value={formData[`input_${field.id}`] || ''}
                                                onChange={handleInputChange}
                                                aria-describedby={`validationFeedback_${field.id}`}
                                            />
                                            <label htmlFor={`input_${field.id}`}>{field.placeholder || field.label}</label>
                                            <div id={`validationFeedback_${field.id}`} className="invalid-feedback">
                                                {validationErrors[`input_${field.id}`]}
                                            </div>
                                        </div>
                                    </div>
                                );
                            case 'select':
                                return (
                                    <div className="mb-3">
                                        {field.description && <p className="small mb-1 text-muted">{field.description}</p>}
                                        <div className="form-floating">
                                            <select
                                                key={field.id}
                                                className={`form-select ${validationErrors[`input_${field.id}`] ? 'is-invalid' : ''}`}
                                                id={`input_${field.id}`}
                                                name={`input_${field.id}`}
                                                value={formData[`input_${field.id}`] || ''}
                                                onChange={handleInputChange}
                                                aria-label={field.label}
                                            >
                                                <option value="">{`Select ${field.label}`}</option>
                                                {field.choices && field.choices.map(choice => (
                                                    <option key={choice.value} value={choice.value}>
                                                        {choice.text}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor={`input_${field.id}`}>{field.label}</label>
                                            <div id={`validationFeedback_${field.id}`} className="invalid-feedback">
                                                {validationErrors[`input_${field.id}`]}
                                            </div>
                                        </div>
                                    </div>
                                );
                            case 'html':
                                return (
                                    <div className={"d-flex justify-content-center flex-column my-3"}>
                                        <div className={"d-flex justify-content-center"}>
                                            <p className={"text-center mb-0"}
                                               dangerouslySetInnerHTML={{__html: field.content}}/>
                                        </div>
                                    </div>
                                );
                            case 'captcha':
                                return (
                                    <div className={"d-flex justify-content-center flex-column my-3"}>
                                        <div className={"d-flex justify-content-center"}>
                                            <ReCAPTCHA
                                                sitekey={RECAPTCHA_SITE_KEY}
                                                onChange={onReCAPTCHAChange}
                                                className={"mx-auto"}
                                            />
                                        </div>
                                        <div>
                                            {validationErrors.recaptcha && (
                                                <div className="invalid-feedback d-block text-center mt-2">
                                                    {validationErrors.recaptcha}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            // Add cases for other field types
                            default:
                                return null;
                        }
                    })}

                    <div className={"text-center mt-4"}>
                        <button
                            className="btn btn-secondary btn-lg w-50"
                            type="submit"
                        >
                            {buttonText}
                        </button>
                    </div>

                </form>
            ) : (
                <div className="d-flex align-items-center justify-content-center flex-column py-5">
                    <Lottie
                        animationData={checkmarkAnimation}
                        loop={false}
                        autoplay
                        style={{width: '150px', height: '150px'}} // Adjust the size as needed
                    />
                    <h3 className="text-center fw-normal fs-1 mb-4">{confirmationMessage}</h3>
                    {/*<Link className={"btn btn-secondary btn-lg"} to={"/"}>Return Home</Link>*/}
                </div>
            )}

        </div>
    );

}

export default GravityFormsComponent;
