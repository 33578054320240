import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { fetchPosts } from '../api/posts';
import {Card, CardBody, CardTitle, CardText, CardFooter} from "reactstrap";

import '../styles/child-theme.scss';
import SEOComponent from "./SEOComponent";

function PostListComponent() {

    const [posts, setPosts] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [page, setPage] = useState(1);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);  // New state variable for loading
    const observer = useRef();

    const lastPostElementRef = useCallback(node => {
        if (loading) return;  // Don't observe if posts are being loaded
        if (hasMore && node) {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    setPage(prevPage => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        }
    }, [hasMore, loading]);  // Include loading in the dependency array

    useEffect(() => {
        setLoading(true);
        fetchPosts(page)
            .then(({ posts, totalPages: fetchedTotalPages }) => {
                setTotalPages(fetchedTotalPages); // Save the total number of pages
                setHasMore(page < fetchedTotalPages); // Update hasMore based on whether there are more pages
                setPosts(prevPosts => [...prevPosts, ...posts]);
            })
            .catch(error => {
                // console.log("dude error catch");
                //setError(`Error: ${error.message}`);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [page]);

    if (error) {
        // Commented out to prevent error message from displaying
        //return <div>Error: {error}</div>;
    }

    return (

        <div className="container">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 justify-content-center">
                {posts.map((post, index) => {
                    const isWider = (index % 9 === 0) || (index % 9 === 4) || (index % 9 === 8);
                    const wordLimit = isWider ? 20 : 10;  // Set the word limit to 40 for wider posts and 20 for narrow ones
                    const words = post.excerpt.rendered.split(' ');
                    let limitedContent = words.slice(0, wordLimit).join(' ');

                    if (words.length > wordLimit) {
                        limitedContent += "...";
                    }

                    return (
                        <div
                            className={`col ${isWider ? 'col-lg-6' : 'col-lg-3'}`}
                            key={post.id}
                            ref={index === posts.length - 1 ? lastPostElementRef : null}
                        >
                            <Card className="h-100 border-0 border-radius-0">
                                <div className="img-wrapper">
                                    <div className="overlay"></div>
                                    <div
                                        className="card-img-top img-fluid image-container"
                                        style={{
                                            backgroundImage: `url(${
                                                post._embedded && post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0].source_url
                                                    ? post._embedded['wp:featuredmedia'][0].source_url
                                                    : "https://picsum.photos/300/200"
                                            })`
                                        }}
                                    ></div>
                                </div>
                                <CardBody className={"pb-0"} style={{ backgroundColor: 'var(--bs-primary-light)' }}>
                                    <CardTitle className="text-primary" tag="h3" dangerouslySetInnerHTML={{ __html: post.title.rendered }}/>
                                    <CardText dangerouslySetInnerHTML={{ __html: limitedContent }} />
                                </CardBody>
                                <CardFooter className={"p-3 pt-0 border-0"} style={{ backgroundColor: 'var(--bs-primary-light)' }}>
                                    <Link
                                        to={`/resources/${post.slug}`}
                                        className="btn btn-secondary btn-5 stretched-link">
                                        Learn More
                                    </Link>
                                </CardFooter>

                            </Card>
                        </div>
                    );
                })}
            </div>

            {/* ... // Display loading message/spinner ... */}
            {loading && (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100px' }}>
                    <div className="spinner-grow text-secondary fs-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            {/* ... // No more posts available ... */}
            {!hasMore && !loading && <div className={"text-center py-4"}>No more posts available.</div>}

        </div>
    );

}

export default PostListComponent;
