import React, { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import { fetchPosts, fetchPostBySlug } from '../api/posts';

import SEOComponent from "./SEOComponent";
import PageBannerComponent   from "./PageBannerComponent";
import useDelayedVisibility from "../hooks/useDelayedVisibility";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function SinglePostComponent() {
    // Get the post ID from the URL
    const { slug } = useParams();

    const [post, setPost] = useState(null);
    const [posts, setPosts] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const [error, setError] = useState(null);

    // Set posts current index for nav
    const currentIndex = Array.isArray(posts) ? posts.findIndex(post => post.slug === slug) : -1;

    //set loading and loaded const
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // Use the fetchPost function to get the post data
        fetchPosts()
            .then(data => {
                //console.log('Posts:', data); // Check the fetched posts
                setPosts(data.posts);
                setLoading(false);
                setLoaded(true);
            })
            .catch(err => {
                setError(err.message);
            });
    }, []);

    useEffect(() => {
        // Use the fetchPost function to get the post data
        fetchPostBySlug(slug)
            .then(data => {
                //console.log(data);
                setPost(data[0]);
                setLoading(false);
                setLoaded(true);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, [slug]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!post) {
        return <p>No post found.</p>;
    }

    function Navigation({ post, posts, currentIndex, loaded, delay }) {

        const navVisible = useDelayedVisibility(loaded, delay);

        console.log({ post, posts, currentIndex, loaded, navVisible });

        // Handle missing or empty posts
        if (!Array.isArray(posts) || posts.length === 0) {
            return null; // Or some placeholder component
        }

        return (
            <div key={post?.id} className={`container-fluid bg-light py-3 fade-in ${navVisible ? 'show' : ''}`}>
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-md-auto text-center text-md-start">
                        <Link className="btn btn-link fw-bold text-decoration-none" to="/resources">
                            <FontAwesomeIcon icon="arrow-left" className="text-secondary me-2"/> Resources
                        </Link>
                    </div>
                    <div className="col text-center d-none d-lg-block">
                        <div
                            className="text-primary fw-bold"
                            dangerouslySetInnerHTML={{ __html: post.title && post.title.rendered }} />
                    </div>
                    <div className="col-12 col-md-auto text-end d-flex justify-content-between">
                        {currentIndex > 0 ? (
                            <Link className="btn btn-link fw-bold text-decoration-none" to={`/resources/${posts[currentIndex - 1].slug}/`}>
                                <FontAwesomeIcon icon="arrow-left" className="text-secondary me-2"/>Previous
                            </Link>
                        ) : (
                            <span className="btn btn-link fw-bold text-decoration-none disabled">
                            <FontAwesomeIcon icon="arrow-left" className="text-secondary me-2"/>Previous
                        </span>
                        )}
                        {currentIndex < posts.length - 1 ? (
                                <Link className="btn btn-link fw-bold text-decoration-none" to={`/resources/${posts[currentIndex + 1].slug}/`}>
                                Next<FontAwesomeIcon icon="arrow-right" className="text-secondary ms-2"/>
                            </Link>
                        ) : (
                            <span className="btn btn-link fw-bold text-decoration-none disabled">
                            Next<FontAwesomeIcon icon="arrow-right" className="text-secondary ms-2"/>
                        </span>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    // Load Default Page Data for Banner
    const defaultPageData = {
        id: 245678,
        acf: {
            static_banner:{
                banner_image: 2667,
                banner_headline: 'Resources',
                banner_subhead: post.title.rendered
            }
        },
        title: {
            rendered: 'Resources'
        }
    };

    return (

        <div key={post?.id}>

            {/* Insert SEO meta tags */}
            <SEOComponent yoastHeadContent={post.yoast_head} />

            {/* Load Post Navigation */}
            <Navigation post={post} posts={posts} currentIndex={currentIndex} loaded={loaded} delay={50} />

            <div className="container pt-5">
                <div className="row">
                    <div className="col">
                        <h1 className="text-primary mb-3" tag="h3" dangerouslySetInnerHTML={{ __html: post.title && post.title.rendered }}/>
                    </div>
                </div>
            </div>

            {/* Display the Post Content */}
            <div dangerouslySetInnerHTML={{ __html: post.content && post.content.rendered }} />

            {/* ... // Display loading message/spinner ... */}
            {loading && (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100px' }}>
                    <div className="spinner-grow text-secondary fs-2" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

        </div>

    );
}

export default SinglePostComponent;