import React, { useState, useEffect, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { fetchMainMenu } from '../api/menus';

import {
    Collapse,
    Navbar,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import '../styles/child-theme.scss';

function MainMenuComponent({ menuItems, isOpen, toggle }) {

    const [error, setError] = useState(null);
    const location = useLocation();

    const isActive = (href) => {
        return formatPath(href) === location.pathname;
    };

    const formatPath = (href) => {
        let path = new URL(href).pathname;
        // Remove '/wordpress/' from the path
        return path.replace(/^\/wordpress\//, '/');
    };

    const isChildActive = (children) => {
        return children.some(child => new URL(child.href).pathname === location.pathname);
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    const renderMenuItem = (item) => {
        if (item.children) {
            return (
                <UncontrolledDropdown nav inNavbar key={item.id}>
                    <DropdownToggle nav caret className={isActive(item.href) ? 'active' : ''}>
                        {item.name}
                    </DropdownToggle>
                    <DropdownMenu className={'bg-white'}>
                        {item.children.map(subItem => renderSubMenuItem(subItem))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            );
        } else {
            return (
                <NavItem key={item.id}>
                    <Link
                        to={formatPath(item.href)}
                        className={`nav-link ${isActive(item.href) ? 'active' : ''}`}
                    >
                        {item.name}
                    </Link>
                </NavItem>
            );
        }
    };

    const renderSubMenuItem = (subItem) => {
        if (subItem.children) {
            return (
                <DropdownItem key={subItem.id} toggle={false} className="dropdown-submenu">
                    <Link
                        to={formatPath(subItem.href)}
                        className={`nav-link d-flex justify-content-between align-items-center ${isActive(subItem.href) ? 'active' : ''}`}
                    >
                        {subItem.name}
                    </Link>
                    <DropdownMenu className={'bg-white'}>
                        {subItem.children.map(grandchildItem => (
                            <DropdownItem key={grandchildItem.id}>
                                <Link
                                    to={formatPath(grandchildItem.href)}
                                    className={`nav-link position-relative ${isActive(grandchildItem.href) ? 'active' : ''}`}
                                >
                                    {grandchildItem.name}
                                </Link>
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </DropdownItem>
            );
        } else {
            return (
                <DropdownItem key={subItem.id}>
                    <Link
                        to={formatPath(subItem.href)}
                        className={`nav-link ${isActive(subItem.href) ? 'active' : ''}`}
                    >
                        {subItem.name}
                    </Link>
                </DropdownItem>
            );
        }
    };

    return (
        <Navbar color="white" expand="lg" className="container-fluid mt-3 mt-lg-0">
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ms-auto" navbar>
                    {menuItems.map(item => renderMenuItem(item))}
                </Nav>
            </Collapse>
        </Navbar>
    );
}

export default MainMenuComponent;
