import React from "react";
import {useParams} from "react-router-dom";
import PastWinnerComponent from "../components/PastWinnerComponent";

function PastWinnerPage() {

    const { pastWinner } = useParams();

    return (
        <div className="App">
            <PastWinnerComponent slug={pastWinner} />
        </div>
    );
}

export default PastWinnerPage;