import React from 'react';

// Assets
import '../styles/child-theme.scss';

// Components
import SinglePostComponent from "../components/SinglePostComponent";
import SEOComponent from "../components/SEOComponent";
import PageBannerComponent from "../components/PageBannerComponent";

function SinglePostPage({ postId }) { // changed prop from slug to postId

    return (
        <div className="App">
            <SinglePostComponent postId={postId} />
        </div>
    );
}

export default SinglePostPage;