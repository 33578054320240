import { BASE_URL, API_PATH, USERNAME, APP_PASSWORD } from '../config';
const authHeader = 'Basic ' + btoa(USERNAME + ':' + APP_PASSWORD);

export const fetchPage = (pageId) => {
    const url = `${BASE_URL}${API_PATH}/pages/${pageId}`;
    console.log('Fetching page with URL:', url);
    console.log('Authorization header:', authHeader);

    return fetch(url, {
        headers: {
            'Authorization': authHeader
        }
    })
        .then(response => {
            console.log('fetchPage Response status:', response.status);
            console.log('fetchPage Response headers:', response.headers);
            if (!response.ok) {
                throw new Error('This Network response was not ok');
            }
            return response.json();
        });
};

export const fetchPageBySlug = (slug) => {

    const url = `${BASE_URL}${API_PATH}/pages?slug=${slug}`;
    console.log('Fetching page by slug with URL:', url);
    console.log('Authorization header:', authHeader);

    return fetch(url, {
        headers: {
            'Authorization': authHeader
        }
    })
        .then(response => {
            console.log('fetchPage Response status:', response.status);
            console.log('fetchPage Response headers:', response.headers);
            if (!response.ok) {
                throw new Error('This Network response was not ok');
            }
            return response.json();
        });
}

// Get JWT Token so Preview will work
export const getJWTToken = (username, password) => {
    return fetch(`${BASE_URL}/wp-json/jwt-auth/v1/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username,
            password,
        }),
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to obtain JWT token');
            }
            return response.json();
        })
        .then(data => data.token)
        .catch(error => {
            console.error('Error obtaining JWT token:', error);
        });
};

// Add this function to handle preview - requires JWT token
export const fetchPreviewPageWithJWT = (previewId, token) => {
    const url = `${BASE_URL}/wp-json/wp/v2/pages/${previewId}/autosaves`;

    console.log('Fetching preview page with URL:', url);
    console.log('JWT token:', token);

    return fetch(url, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
        },
        credentials: 'include' // Ensure cookies are included in the request
    })
        .then(response => {
            console.log('fetchPreviewPageWithJWT Response status:', response.status);
            if (!response.ok) {
                throw new Error(`This Network response was not ok, status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (data.length === 0) {
                throw new Error('No autosaves found');
            }
            console.log('Fetched autosave data:', data[0]);
            return data[0]; // Assuming the first item is the latest autosave
        })
        .catch(error => {
            console.error('Error fetching preview with JWT:', error);
        });
};

export const fetchPastWinners = () => {
    return fetch(`${BASE_URL}${API_PATH}/pages?slug=past-winners`, {
        headers: {
            'Authorization': authHeader
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(pages => {
            if (pages.length > 0) {
                const parentId = pages[0].id;
                return fetch(`${BASE_URL}${API_PATH}/pages?parent=${parentId}`, {
                    headers: {
                        'Authorization': authHeader
                    }
                });
            } else {
                throw new Error('Page not found');
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(childPages => {
            // Process child pages
            return childPages;
        })
        .catch(error => {
            console.error('Error:', error);
            throw error;
        });
};



