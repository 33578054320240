import React, { useState } from 'react';

// Assets
import '../styles/child-theme.scss';
import logo from "../logo.svg";
import PageContentComponent from "../components/PageContentComponent";
import SuccessStorySlider from "../components/SuccessStorySlider";
import GravityFormsComponent from "../forms/GravityFormsComponent";

function HomePage({ pageId }) {

    const [contentLoaded, setContentLoaded] = useState(false);
    const handleContentLoaded = () => {
        setContentLoaded(true); // Update state when content is loaded
    };

    return (
        <div>
            <PageContentComponent pageId={pageId} onContentLoaded={handleContentLoaded} />
            <SuccessStorySlider />
            <div className={"bg-light py-5"}>
                <div className={"container"}>
                    <div className={"row justify-content-center"}>
                        <div className="col col-12 text-center">
                            <h2 className="wp-block-heading text-center mb-4">Get Updates</h2>
                            <p>Receive the latest information on funding, advice and connections direct to your inbox.</p>
                        </div>
                        <div className={"col col-12 col-lg-6"} id={"stay-informed"}>
                            <GravityFormsComponent formId={4}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
