import {BASE_URL, API_PATH, GF_CONSUMER_SECRET, GF_CONSUMER_KEY, USERNAME, APP_PASSWORD} from '../config';
//const authHeader = 'Basic ' + btoa(GF_CONSUMER_KEY + ':' + GF_CONSUMER_SECRET);
const authHeader = 'Basic ' + btoa(USERNAME + ':' + APP_PASSWORD);

// New call to submit form to gravity forms
export const submitFormById = (formId, formData) => {

    //console.log(formData);

    return fetch(`${BASE_URL}/wp-json/gf/v2/forms/${formId}/submissions`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader
        },
        body: JSON.stringify(formData),
    })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(obj => {
            if (obj.status !== 200) {
                console.error('Error submitting form:', obj.body);
                throw new Error(obj.body.message); // Adjust based on your error message structure
            }
            // Handle successful response here
            return obj.body; // Return the response body for further processing
        })
        .catch(error => {
            console.error('Error submitting form:', error);
            throw error; // Rethrowing the error to be handled by the caller
        });
};


// New call to get all forms from gravity
export const fetchAllForms = () => {
    return fetch(`${BASE_URL}/wp-json/gf/v2/forms`, {
        headers: {
            'Authorization': authHeader
        }
    })
        .then(response => {
            if (!response.ok) {
                // If the response status code is not in the 200-299 range,
                // throw an error with the status code and text for further handling.
                throw new Error(`HTTP error! status: ${response.status}, statusText: ${response.statusText}`);
            }
            return response.json();
        })
        .catch(error => {
            // Handle any errors that fell through the .then() checks
            // This will include network errors, permission errors, etc.
            console.error('Error:', error.message);
        });
}

// New call to get single form from gravity forms
export const fetchFormById = (formId) => {
    return fetch(`${BASE_URL}/wp-json/gf/v2/forms/${formId}`, {
        headers: {
            'Authorization': authHeader
        }
    })
        .then(response => {
            if (!response.ok) {
                // If the response is not successful, throw a detailed error.
                return response.text().then(text => {
                    const errMsg = `HTTP error! status: ${response.status}, statusText: ${response.statusText}, body: ${text}`;
                    throw new Error(errMsg);
                });
            }
            return response.json();
        })
        .catch(error => {
            // Network errors will fall into this block
            if (error.name === "TypeError" && error.message === "Failed to fetch") {
                console.error('Network error: The request may have been blocked or the URL is not reachable.');
            } else {
                console.error('API error:', error);
            }
            throw error; // Re-throw the error so you can handle it where you call fetchFormById
        });
}



