import React, { useEffect, useState } from 'react';
import { fetchFooter } from "../api/acf-options";
import { adjustLinks } from "../hooks/useAdjustLinks";

import '../styles/child-theme.scss';

function Footer() {

    const [columns, setColumns] = useState(null);
    const [error, setError] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    // Get data from fetchFooter function
    useEffect(() => {
        fetchFooter()
            .then(data => {
                //console.log(data);
                setColumns(data);
            })
            .catch(err => setError(err.message));
    }
    , []);

    return (
        <footer className="footer bg-primary">
            <div className="container py-5">

                <div className={`row row-cols-1 ${columns ? `row-cols-lg-${columns.length}` : ''} g-3 justify-content-center`}>
                    {columns && columns.map((column, index) => {
                        let colClass = 'col';
                        if (columns.length === 3) {
                            if (index === 1) {
                                colClass = 'col-lg-8'; // Make middle column wider when there are 3 columns
                            } else {
                                colClass = 'col-md-6 col-lg-2'; // Make first and last columns narrower when there are 3 columns
                            }
                        } else {
                            colClass = `col-lg-${12 / columns.length}`;  // Distribute columns evenly
                        }
                        return (
                            <div className={colClass}
                                key={index}
                                dangerouslySetInnerHTML={{ __html: adjustLinks(column.column_content) }}
                            />
                        );
                    })}
                </div>

                <div className="row">
                    <div className="col">
                        <p className="text-center text-white fs-6">© {new Date().getFullYear()} Otago Innovation Limited</p>
                    </div>
                </div>

            </div>
        </footer>
    );
}

export default Footer;

