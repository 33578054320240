import { BASE_URL, API_PATH, USERNAME, APP_PASSWORD } from '../config';

const authHeader = 'Basic ' + btoa(USERNAME + ':' + APP_PASSWORD);

// Get all partners from partner endpoint
export const fetchAllPartners = () => {
    return fetch(`${BASE_URL}${API_PATH}/partners`, {
        headers: {
            'Authorization': authHeader
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        });
}