import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation
} from 'react-router-dom';

// Import Components
import Header from "./components/HeaderComponent";
import Footer from "./components/FooterComponent";
import ScrollToTop from "./components/ScrollToTop";

// Import Hooks
import ConditionalPartnerComponent from "./hooks/useConditionPartnerComponent";

// Import API functions
import { fetchMainMenu } from './api/menus';  // Assuming you have this function set up to fetch your main menu

// Import
import HomePage from './pages/HomePage';
import PostsPage from './pages/PostsPage';
import GenericPage from './pages/GenericPage'; // This would fetch the content based on postId
import SinglePostPage from "./pages/SinglePostPage";
import TeamMemberDetailPage from "./pages/TeamMemberDetailPage";
import SuccessStoryDetailPage from "./pages/SuccessStoryDetailPage";
import PastWinnerPage from "./pages/PastWinnerPage";
import Error404Page from "./pages/Error404Page";

import useGA4Tracker from "./hooks/useGA4Tracker";

function extractSlugFromHref(href) {
    const url = new URL(href);
    // Replace '/wordpress/' with '/'
    const newPathname = url.pathname.replace('/wordpress/', '/');
    // Remove leading and trailing slashes
    return newPathname.replace(/^\//, '').replace(/\/$/, '');
}

const PageViewTracker = () => {
    useGA4Tracker(); // This hooks call is now safely inside a component
    return null; // This component doesn't render anything
};

const AppRouter = () => {

    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        // Fetch the main menu on mount
        fetchMainMenu()
            .then(data => {
                setMenuItems(data);
            })
            .catch(err => console.error(err));
    }, []);

    return (
        <Router>
            <ScrollToTop />
            <PageViewTracker /> {/* This ensures the hook is used within the Router context */}
            <Header menuItems={menuItems}/>
            <Routes>

                {/* Static routes can stay as they are */}
                <Route path="/" element={<HomePage pageId={701}/>} />
                <Route path="/resources" element={<PostsPage />} />
                <Route path="/resources/:slug" element={<SinglePostPage />} />
                <Route path="/team/:memberName" element={<TeamMemberDetailPage />} />

                <Route path={"/about/meet-the-team/"} element={<GenericPage pageId={1891} />} />
                <Route path={"/terms-conditions/"} element={<GenericPage pageId={2224} />} />
                <Route path={"/privacy-policy/"} element={<GenericPage pageId={3} />} />

                <Route path={"/register-your-interest/"} element={<GenericPage pageId={3448} />} />
                <Route path={"/thank-you/"} element={<GenericPage pageId={3437} />} />
                <Route path={"/contact-thank-you/"} element={<GenericPage pageId={3665} />} />

                <Route path={"/funding/proof-of-concept-competition/#past-winners"} element={<GenericPage pageId={2000} />} />

                {/* Success Story dynamic routes */}
                {menuItems.flatMap(item => item.children || []).map(child => {
                    const childSlug = extractSlugFromHref(child.href);
                    if (childSlug.startsWith('success-story/')) {
                        const storySlug = childSlug.replace('success-story/', '');
                        return (
                            <Route
                                key={child.postid}
                                path={`/success-story/${storySlug}`}
                                element={<SuccessStoryDetailPage slug={storySlug} />}
                            />
                        );
                    }
                    return null;
                })}

                <Route path="/success-story/:slug" element={<SuccessStoryDetailPage />} />

                {/* Dynamically generate routes based on menu items */}
                {menuItems.map(item => {
                    const slug = extractSlugFromHref(item.href);
                    return (
                        <Route
                            key={item.postid}
                            path={`/${slug}`}
                            element={<GenericPageWithQuery pageId={item.postid} />}
                        />
                    );
                })}

                {/* Dynamically generate routes for all child menu items across all menu items */}
                {menuItems.flatMap(item => item.children || []).map(child => {
                    const childSlug = extractSlugFromHref(child.href);
                    return (
                        <Route
                            key={child.postid}
                            path={`/${childSlug}`}
                            element={<GenericPageWithQuery pageId={child.postid} />}
                        />
                    );
                })}

                {/* Dynamically generate routes for grandchild menu items */}
                {menuItems.flatMap(item => item.children || [])
                    .flatMap(child => {
                        // If the child has its own children, map those to routes
                        if (child.children) {
                            return child.children.map(grandchild => {
                                const grandchildSlug = extractSlugFromHref(grandchild.href);

                                // Check if it's a team member and route accordingly
                                if (grandchildSlug.startsWith('team/')) {
                                    const memberName = grandchildSlug.replace('team/', '');
                                    return (
                                        <Route
                                            key={grandchild.postid}
                                            path={`/team/${grandchildSlug}`}
                                            element={<TeamMemberDetailPage />}
                                        />
                                    );
                                }

                                return (
                                    <Route
                                        key={grandchild.postid}
                                        path={`/${grandchildSlug}`}
                                        element={<GenericPageWithQuery pageId={grandchild.postid} />}
                                    />
                                );
                            });
                        }
                        return [];
                    })
                }

                {/* Dynamic route for 'funding/past-winners/:year-winners' */}
                <Route path="/funding/past-winners/:pastWinner" element={<PastWinnerPage />} />

                {/* Handle 404 errors - This should be the LAST route */}
                <Route path="*" element={<Error404Page />} />
            </Routes>

            <ConditionalPartnerComponent />

            <Footer/>
        </Router>
    );
}

const GenericPageWithQuery = ({ pageId }) => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();
    const previewMode = query.get('preview') === 'true';
    return <GenericPage pageId={pageId} previewMode={previewMode} />;
};

export default AppRouter;