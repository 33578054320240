// Create page for Success Story details
import React from 'react';
import SuccessStoryDetailComponent from "../components/SuccessStoryDetailComponent";

function SuccessStoryDetailPage({ slug }) {
    return (
        <div className="App">
            <SuccessStoryDetailComponent slug={slug} />
        </div>
    );
}

export default SuccessStoryDetailPage;