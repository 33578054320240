import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import {BASE_URL, USERNAME, APP_PASSWORD} from "../config";

// Import required API functions
import { fetchPage, fetchPreviewPageWithJWT, getJWTToken } from '../api/pages';
import { fetchImageURL } from '../api/media';

// Components
import SEOComponent from "./SEOComponent";
import PageBannerComponent from "./PageBannerComponent";
import TypingAnimationComponent from "./TypingAnimationComponent";
import GravityFormsComponent from "../forms/GravityFormsComponent";

import '../styles/child-theme.scss';

function PageContentComponent({ pageId, previewMode }) {

    const [page, setPage] = useState(null);
    const [error, setError] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const formContainerRef = useRef(null);

    // A function to transform links in the page content to React Router links
    const transformLink = (node) => {
        if (node.type === 'tag' && node.name === 'a') {
            let href = node.attribs.href;
            const className = node.attribs.class;

            // Remove BASE_URL from href
            href = href.replace(BASE_URL, '');

            // Remove '/wordpress' from href if present
            href = href.replace('/wordpress', '');

            // A helper function to convert a style string to an object
            const convertStyleToObject = (styleString) => {
                return styleString.split(';').reduce((styleObject, stylePropertyString) => {
                    let [key, value] = stylePropertyString.split(':');
                    if (key && value) {
                        styleObject[key.trim()] = value.trim();
                    }
                    return styleObject;
                }, {});
            };

            // A helper function to recursively render child nodes
            const renderChildNode = (childNode) => {
                if (childNode.type === 'tag') {
                    const { name, attribs, children } = childNode;
                    // Convert the style string to an object if it is present
                    if (attribs.style) {
                        attribs.style = convertStyleToObject(attribs.style);
                    }
                    // Rename the 'class' property to 'className'
                    if (attribs.class) {
                        attribs.className = attribs.class;
                        delete attribs.class;
                    }
                    return React.createElement(
                        name,
                        attribs,
                        children && children.map(renderChildNode)
                    );
                } else if (childNode.type === 'text') {
                    return childNode.data;
                }
            };

            return (
                <Link to={href} className={className}>
                    {node.children && node.children.map(renderChildNode)}
                </Link>
            );
        }

        // Look for the placeholder div and replace it with the React form component
        if (node.type === 'tag' && node.name === 'div' && node.attribs && node.attribs.id) {
            // Extract the number from the id attribute and pass it to the GravityFormsComponent
            const match = node.attribs.id.match(/insert-gravity-form-(\d+)/);
            if (match) {
                const formId = match[1];
                return <GravityFormsComponent formId={formId} />;
            }
        }
    };

    useEffect(() => {
        // Define a function that will be responsible for fetching the image URL if needed
        const maybeFetchImageUrl = (pageData) => {
            if (pageData && pageData.acf && pageData.acf.banner_image) {
                // If there's an image to fetch, return the fetchImageURL promise
                return fetchImageURL(pageData.acf.banner_image);
            } else {
                // Otherwise, return a resolved promise with null to indicate no image is needed
                return Promise.resolve(null);
            }
        };

        // Start by fetching the page or preview data based on previewMode
        const fetchData = async () => {
            try {
                let pageData;
                if (previewMode) {
                    const token = await getJWTToken(USERNAME, APP_PASSWORD); // Replace with actual credentials
                    pageData = await fetchPreviewPageWithJWT(pageId, token);
                } else {
                    pageData = await fetchPage(pageId);
                }

                // If preview mode, access the first element of the array
                const finalPageData = previewMode && Array.isArray(pageData) ? pageData[0] : pageData;
                setPage(finalPageData); // Set the page data
                const url = await maybeFetchImageUrl(finalPageData); // Fetch the image URL if needed
                if (url) {
                    setImageUrl(url); // Set the image URL
                }
            } catch (err) {
                setError(err.message); // Handle any errors that occur
            }
        };

        fetchData();
    }, [pageId, previewMode]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!page) {
        return <div>Loading...</div>;
    }

    // Safely access acf properties
    const acf = page.acf || {};
    const homePageBannerHeadlines = acf.home_page_banner_headlines || [];
    const homePageBannerSubhead = acf.home_page_banner_subhead || '';
    const homePageBannerImage = acf.home_page_banner_image || null;

    return (
        <div key={page.id}>
            {/* Conditionally insert SEO meta tags */}
            {!previewMode && <SEOComponent yoastHeadContent={page.yoast_head} />}

            {/* Show preview banner */}
            {previewMode ? (
                <section className="container-fluid bg-secondary px-0 py-3">
                    <div className="col text-center">
                        <div>PREVIEW</div>
                    </div>
                </section>
            ) : null}

            {/* If page.acf.home_page_banner_headlines exists, get the Typing Animation */}
            {homePageBannerHeadlines.length > 0 ? (
                <TypingAnimationComponent
                    texts={homePageBannerHeadlines.map(headline => headline.home_page_banner_headline)}
                    subhead={homePageBannerSubhead}
                    image={homePageBannerImage}
                />
            ) : null}

            {/* Insert Page Banner */}
            {homePageBannerHeadlines.length === 0 ? (
                <PageBannerComponent page={page} />
            ) : null}

            {/* Insert Page Content */}
            <section className="container-fluid px-0">
                <div className="col">
                    {page.content && page.content.rendered ? (
                        parse(page.content.rendered, { replace: transformLink })
                    ) : (
                        <div>No content available</div>
                    )}
                </div>
            </section>
        </div>
    );
}

export default PageContentComponent;