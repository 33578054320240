import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { fetchHeader } from "../api/acf-options";
import { fetchImageURL } from "../api/media";
import { NavbarToggler } from 'reactstrap';
import { BASE_URL} from "../config";

import ButtonComponent from "./ButtonComponent";
import MainMenuComponent from "./MainMenuComponent";
import ConvertToWebpUrl from "../hooks/useConvertToWebpUrl";

import '../styles/child-theme.scss';

function HeaderComponent({ menuItems }) {

    const [brandImageUrl, setbrandImageUrl] = useState(null);
    const [brandImageMobileUrl, setbrandImageMobileUrl] = useState(null);

    const [items, setItems] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const location = useLocation();
    const [error, setError] = useState(null);

    const closeNav = () => {
        setIsOpen(false);
    };

    const isActive = (href) => {
        return new URL(href).pathname === location.pathname;
    };

    const isChildActive = (children) => {
        return children.some(child => new URL(child.href).pathname === location.pathname);
    };

    useEffect(() => {
        fetchHeader()
            .then(data => {
                setItems(data);
            })
            .catch(err => setError(err.message));
    }, []);

    useEffect(() => {
        if (items) {
            if (items.brand) {
                fetchImageURL(items.brand)
                    .then(url => {
                        const webpUrl = ConvertToWebpUrl(url);
                        setbrandImageUrl(webpUrl);
                    })
                    .catch(err => {
                        console.error("Failed to fetch brand image URL:", err);
                    });
            }

            if (items.mobile_brand) {
                fetchImageURL(items.mobile_brand)
                    .then(url => {
                        const webpUrl = ConvertToWebpUrl(url);
                        setbrandImageMobileUrl(webpUrl);
                    })
                    .catch(err => {
                        console.error("Failed to fetch brand image URL:", err);
                    });
            }
        }
    }, [items]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <header className="header bg-white">
            <div className="container-fluid p-3">
                <div className={`row flex-column flex-lg-row  align-items-center`}>
                    <div className={"col d-flex justify-content-between"}>
                        <img className={`brand-image d-none d-lg-block img-fluid ms-1`} src={brandImageUrl} alt="Otago Innovation" />
                        <img className={`brand-image d-block d-lg-none img-fluid`} src={brandImageMobileUrl} alt="Otago Innovation" />
                        <div className={"navbar d-flex d-lg-none"}>
                            <NavbarToggler onClick={toggle} />
                        </div>
                    </div>
                    <div className={`col d-${isOpen ? 'flex' : 'none'} d-lg-flex flex-grow-1 flex-lg-column justify-content-end align-items-center align-items-lg-end`}>
                        <div className={"d-flex flex-row align-items-center d-none d-lg-flex"}>
                            <div className="social-icons me-3">
                                {/* Setup Social Icons*/}
                                {items && items.social_icons && items.social_icons.map((icon, index) => {
                                    return (
                                        <a
                                            key={index}
                                            href={icon.social_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="social-icon-link"
                                        >
                                           <span className="fa-stack fa-1x">
                                               <i className="fa-solid fa-circle fa-stack-2x"></i>
                                               <i className={`${icon.social_icon} fa-stack-1x fa-inverse`}></i>
                                           </span>
                                        </a>
                                    );
                                })}
                            </div>

                            {/* Setup Header Buttons*/}
                            {items && items.header_buttons && items.header_buttons.map((button, index) => {
                                return (
                                    <ButtonComponent
                                        key={index}
                                        color="secondary"
                                        link={`/${button.button_link}`} // Linking to an anchor on the current page
                                        label={button.button_label}
                                    />
                                );
                            })}
                        </div>

                        {/* Generate Main Menu */}
                        <MainMenuComponent menuItems={menuItems} isOpen={isOpen} toggle={toggle} />

                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderComponent;