import React from 'react';

// Assets
import '../styles/child-theme.scss';

// Components
import PostListComponent from "../components/PostListComponent";
import SEOComponent from "../components/SEOComponent";
import PageBannerComponent from "../components/PageBannerComponent";

function PostsPage() {

    const defaultPageData = {
        id: 12345,
        acf: {
            static_banner:{
                banner_image: 2667,
                banner_headline: 'Resources'
            }
        },
        title: {
            rendered: 'Resources'
        },
        yoast_head: "<title>Resources - Otago Innovation</title><meta name='description' content='Explore our resources to learn more.' />"
    };

    return (
        <div className="App">

            {/* Insert SEO meta tags */}
            <SEOComponent yoastHeadContent={defaultPageData.yoast_head} />

            {/* Insert Page Banner */}
            <PageBannerComponent page={defaultPageData} />

            <section className='my-4'>
                <PostListComponent />
            </section>

        </div>
    );
}

export default PostsPage;