import React, { useState, useEffect } from 'react';

// Import required API functions
import {fetchImageURL} from "../api/media";

// import hooks
import ConvertToWebpUrl from "../hooks/useConvertToWebpUrl";

// Components
import '../styles/child-theme.scss';

function PageBannerComponent({page}) {

    const [imageUrl, setImageUrl] = useState(null);
    const [loading, setLoading] = useState(true); // New state for tracking loading status

    useEffect(() => {
        if (page && page.acf && page.acf.static_banner.banner_image) {
            fetchImageURL(page.acf.static_banner.banner_image).then(url => {
                const webpUrl = ConvertToWebpUrl(url);
                setImageUrl(webpUrl);
                setLoading(false); // Set loading to false once image is loaded
            });
        }
    }, [page]);

    //if (page.acf.static_banner_banner_headline) {
        //console.log(page.acf.banner_headline);
    //}

    if (loading) {
        // Render a loader or placeholder while the data is being fetched
        return (
            <div className="d-flex justify-content-center align-items-center vh-100" style={{ minHeight: '100px' }}>
                <div className="spinner-grow text-secondary fs-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );

    }

    const horizontalPlacement = page.acf.static_banner.image_horizontal_placement || 50;
    const verticalPlacement = page.acf.static_banner.image_vertical_placement || 50;

    return (
        page ? (
            <section
                className='banner internal'
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: `${horizontalPlacement}% ${verticalPlacement}%`
                }}
                key={page.id}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            {page.acf.static_banner.banner_headline ?
                                <p className="h1 text-secondary text-center fw-extrabold" dangerouslySetInnerHTML={{ __html: page.acf.static_banner.banner_headline }} /> :
                                <p className="h1 text-secondary text-center" dangerouslySetInnerHTML={{ __html: page.title.rendered }} />
                            }

                            {page.acf.static_banner.banner_subhead ?
                                <p className="h2 text-white text-center text-normal mt-2" dangerouslySetInnerHTML={{ __html: page.acf.static_banner.banner_subhead }} /> :
                                null
                            }

                        </div>
                    </div>
                </div>
            </section>
        ) : null
    );

}

export default PageBannerComponent;