import { BASE_URL, API_PATH, USERNAME, APP_PASSWORD } from '../config';
const authHeader = 'Basic ' + btoa(USERNAME + ':' + APP_PASSWORD);

export const fetchImageURL = (imageId) => {
    return fetch(`${BASE_URL}${API_PATH}/media/${imageId}`, {
        headers: {
            'Authorization': authHeader
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Media response was not ok');
            }
            return response.json();
        })
        .then(data => {
            return data.source_url; // this will give you the image URL
        });
};